import { css } from 'astroturf';
import { graphql } from 'gatsby';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import React from 'react';

import CTA from '../components/CTA';
import DocHeader from '../components/DocHeader';
import DocsListing from '../components/DocsListing';
import SEO from '../components/SEO';
import DocSupport from '../components/docs/support';
import Layout from '../layout';

import 'react-medium-image-zoom/dist/styles.css';

const styles = css`
  .docContainer {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    flex-direction: column;
    @media (min-width: 40rem) {
      flex-direction: row;
      align-items: flex-start;
    }
  }

  .menu {
    position: sticky;
    top: 70px;
    padding-top: 10px;
    z-index: 9;
    & > ul {
      display: none;
    }

    & button {
      font-weight: 400;
      display: inline-block;
      height: 30px;
      line-height: 28px;
      border-radius: 30px;
      font-size: 1rem;
      padding: 0 1rem;
      background: #107db5;
      color: #fff;
      border: 1px solid #107db5;
      transition: 0.25s ease-out all;
      margin: 0 0 0.5rem;
      &:hocus {
        background: #fff;
        color: #107db5;
      }
    }
    @media (min-width: 40rem) {
      position: relative;
      top: 0;
      overflow-y: visible;
      max-height: initial;
      flex: 1;
      display: block;

      & > button {
        display: none;
      }
      & > ul {
        display: block;
      }
    }
  }

  .open {
    & > ul {
      background: #fff;
      display: block;
      overflow: auto;
      max-height: calc(100vh - 118px);
    }
  }

  .content {
    flex: 2;
    & > *:first-child {
      margin-top: 0;
      margin-bottom: 0;
    }
    @media (min-width: 40rem) {
      padding-left: 1.5rem;
    }

    & :global(.gatsby-highlight) {
      max-width: 40rem;
    }
  }

  .dirNav {
    margin-top: 2rem;
    display: flex;
    justify-content: space-between;

    & svg {
      vertical-align: text-bottom;
      transform: translateY(-2px);
    }
  }

  .additional {
    font-size: 1.17rem;
    margin-top: 4rem;
    text-align: center;
  }

  .badge {
    vertical-align: middle;
    font-size: 0.4em;
    line-height: 10px;
    background: #107db5;
    text-transform: uppercase;
    color: #fff;
    font-weight: bold;
    border-radius: 5px;
    margin-left: 0.5rem;
    padding: 3px 5px 1px;

    &::before {
      content: '';
      display: inline-block;
      height: 10px;
      vertical-align: middle;
    }
  }
`;

export default class PostTemplate extends React.Component {
  constructor() {
    super();

    this.state = {
      menuShown: false,
    };
  }

  showMenu = () => {
    const { menuShown } = this.state;
    this.setState({ menuShown: !menuShown });
  };

  render() {
    const menuClasses = [styles.menu, this.state.menuShown ? styles.open : undefined].filter((x) => x).join(' ');
    const { slug } = this.props.pageContext;
    const postNode = this.props.data.mdx;
    const docEdges = this.props.data.allMdx.edges;
    const post = postNode.frontmatter;
    if (!post.id) {
      post.id = slug;
    }
    return (
      <Layout>
        <SEO
          title={post.title}
          keywords={['']}
          description={postNode.excerpt}
          pathname={slug}
          ogFileName={postNode.fields.ogFileName}
        />
        <DocHeader />
        <div className="widePage">
          <div className={styles.docContainer}>
            <div className={menuClasses}>
              <button type="button" onClick={this.showMenu}>
                {this.state.menuShown ? 'Hide menu' : 'Show menu'}
              </button>
              <DocsListing docEdges={docEdges} />
            </div>
            <section className={styles.content}>
              <h1>
                {post.title} {post.beta ? <small className={styles.badge}>Beta</small> : null}
              </h1>
              <div className="childPadding">
                <MDXRenderer>{postNode.body}</MDXRenderer>
              </div>
              <DocSupport />
            </section>
          </div>
        </div>
        <CTA />
      </Layout>
    );
  }
}

/* eslint no-undef: "off" */
export const pageQuery = graphql`
  query DocsBySlug($slug: String!) {
    mdx(fields: { slug: { eq: $slug } }) {
      body
      timeToRead
      excerpt
      frontmatter {
        title
        cover
        date
        beta
      }
      fields {
        nextTitle
        nextSlug
        prevTitle
        prevSlug
        slug
        date
        ogFileName
      }
    }
    allMdx(
      filter: { fields: { collection: { eq: "docs" } } }
      limit: 2000
      sort: { fields: [fields___slug], order: ASC }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
          }
        }
      }
    }
  }
`;
